import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    avatar: '',
    userName: '',
    email: '',
    permissions: [],
    authority: [],
    project_id: '',
    project_name: ''
}

export const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
        setUser: (_, action) => action.payload,
        updateProjectId: (state, action) => { state.project_id = action.payload },
        updateProjectName: (state, action) => { state.project_name = action.payload },
        updateAuthCount: (state, action) => { state.auth_project = action.payload },
        userLoggedOut: () => initialState,
	},
})

export const { setUser, updateProjectId, updateProjectName, updateAuthCount } = userSlice.actions

export default userSlice.reducer