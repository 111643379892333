import ApiService from "./ApiService"

export async function getKPIscoreTrendsTeam (params) {
    return ApiService.fetchData({
        url: '/stats/kpi_trends',
        method: 'get',
        params
    })
}


export async function getKPIscorePerTeam (params) {
    return ApiService.fetchData({
        url: '/stats/kpi_per',
        method: 'get',
        params
    })
}