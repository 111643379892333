import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getKPIscoreTrends, getKPIscorePer} from 'services/DashboardService'

export const getKPIscoreTrendsData = createAsyncThunk('get/kpiTrends',async () => {
    const response = await getKPIscoreTrends()
    return response.data
})

export const getKPIscorePerData = createAsyncThunk('get/kipScorePer',async () => {
    const response = await getKPIscorePer()
    return response.data
})
 

const dashboardSlice = createSlice({
    name: 'adminDashboard/data',
    initialState: {
        loading: false, //productta true olmalı
        KPIData:[
            {
                title:"Bira",
                list:[
                    {title:"Must Have Availability", type:0, value:49, diff:-3},
                    {title:"Must Have Availability in Bla Bla", type:1, value:52, diff:5},
                    {title:"Planogram 1", type:0, value:52, diff:0}
                ]
            },
            {
                title:"Başka Bişiy",
                list:[
                    {title:"Must Have Availability", type:0, value:49, diff:-3},
                    {title:"Must Have Availability in Bla Bla", type:1, value:52, diff:5},
                    {title:"Planogram 1", type:0, value:52, diff:3.6},
                    {title:"Planogram 2", type:0, value:46, diff:-20.22},
                    {title:"Planogram 3", type:0, value:70, diff:10.7}
                ]
            }
        ],
        KPIScore:  [
                {title:"Must Have Availability", type:0, value:24, diff:-3},
                {title:"Must Have Availability in Bla Bla", type:1, value:55, diff:5},
                {title:"Planogram 1", type:0, value:52, diff:7.6},
                {title:"Planogram 2", type:0, value:46, diff:-0.22},
                {title:"Planogram 3", type:0, value:70, diff:1.7}
            ]
        ,
        KPITrends: { 
			daily: {
				onGoing: 13,
				finished: 9,
				total: 21,
				series: [
					{
						name: "Ziyaret Sayısı",
						data: [20, 19, 18, 14, 12, 10]
					},
					{
						name: "Mağaza Sayısı",
						data: [1, 4, 8, 15, 16, 18]
					}
				],
				range:['6:00am', '9:00am', '12:00pm', '03:00pm', '06:00pm', '09:00pm']
			},
			weekly: {
				onGoing: 126,
				finished: 87,
				total: 213,
				series: [
					{
						name: "Ziyaret Sayısı",
						data: [45, 52, 68, 84, 103, 112, 126]
					},
					{
						name: "Mağaza Sayısı",
						data: [35, 41, 62, 62, 75, 81, 87]
					}
				],
				range:['21 Jan', '22 Jan', '23 Jan', '24 Jan', '25 Jan', '26 Jan', '27 Jan']
			},
			monthly: {
				onGoing: 270,
				finished: 113,
				total: 383,
				series: [
					{
						name: "Ziyaret Sayısı",
						data: [28, 52, 91, 154, 227, 256, 270]
					},
					{
						name: "Mağaza Sayısı",
						data: [22, 31, 74, 88, 97, 107, 113]
					}
				],
				range:['01 Jan', '05 Jan', '10 Jan', '15 Jan', '20 Jan', '25 Jan', '27 Jan']
			}, 
        }
    },    
    reducers: {
    },
    extraReducers: {
        [getKPIscoreTrendsData.fulfilled]: (state, action) => {
            state.KPITrends = action.payload
            state.loading = false
        },
        [getKPIscoreTrendsData.pending]: (state) => {
            state.loading = true
        },
        [getKPIscorePerData.fulfilled]: (state, action) => {
            state.KPIScore = action.payload
            state.loading = false
        },
        [getKPIscorePerData.pending]: (state) => {
            state.loading = true
        }
    }
})

export default dashboardSlice.reducer
