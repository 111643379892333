import { createSlice } from '@reduxjs/toolkit'

export const initialData = {
    data: {},
    show:false,
    loading: false,
    list: [],
    index: []
}

const pollDrawerSlice = createSlice({
    name: 'pollDrawer',
    initialState: {...initialData },
    reducers: {
        updateDrawer: (state, action) => {
            state.data = { ...action.payload }
        },
        closeDrawer: (state) => {
            state.show = false
        },
        openDrawer: (state) => {
            state.show = true
        },
        updateList: (state, action) => {
            let dir = [];
            action.payload.map((item) => {
                dir.push(item.customer_sap_code)
            })
            state.index = dir
            state.list = action.payload
        }
    },
    extraReducers: {
       /* [updateDrawer.fulfilled]: (state, action) => {
            state.loading = false
        },
        [updateDrawer.pending]: (state) => {
            state.loading = true
        },*/
    }
})

export const { updateDrawer, openDrawer, closeDrawer, updateList } = pollDrawerSlice.actions

export default pollDrawerSlice.reducer