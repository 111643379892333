import { PERSIST_STORE_NAME } from 'constants/app.constant'
import rootReducer from './rootReducer'
import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import appConfig from 'configs/app.config'
import { apiCheckAuth } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import { setUser } from 'store/auth/userSlice'
import jwt_decode from 'jwt-decode';

const checkAuth = async(store) => {
	const {auth} =  await store.getState();
	if(auth.session.token){
		///check 
		try {		
			const resp = await apiCheckAuth({ token:auth.session.token }) // token post içerisinde data olarak gitmeli mi ? ekledim ama.
			//const resp = await apiCheckAuth() 
			if(resp){
				if(resp.data.Status=='Normal'){
					var decoded = await jwt_decode(resp.data.Content);
					store.dispatch(onSignInSuccess(resp.data.Content))
				
					store.dispatch(setUser(resp.data.user || { 
						avatar: '', 
						userName: decoded.name_surname, 
						authority: [decoded.position_name], 
						email: decoded.e_mail,
						permissions: [301,600]
					}))

					
				}else {
					//console.log('__!___')
					//kullanıcıyı çıkart //FIXME: bu kısım cross domain izininden sonra devreye alınmalı
					store.dispatch(onSignOutSuccess())
				} 
			}
		} catch (error) {
			store.dispatch(onSignOutSuccess())
		}
		//console.log(resp)
	}
}

const onRehydrationMiddleware = store => next => action => {
	// persistence datası yüklenmesini handle eden middleware 
	if (!hasRehydrated && action.type === 'persist/REHYDRATE') {
		hasRehydrated = true; 
		next(action);  
		//persistence yüklendiği için bir kere çalışıcak satır	

		if("true" != process.env.REACT_APP_DEBUG){

			checkAuth(store);
			
			// auth chek döngüsü
			setInterval(() => {
				checkAuth(store);
			}, appConfig.AuthCheckTime);

		}
		
	} else {
		// action'ı çalıştır
		next(action);
	}
};

const middlewares = [onRehydrationMiddleware]

const persistConfig = {
	key: PERSIST_STORE_NAME,
	keyPrefix: '',
	storage,
	whitelist: ['auth', 'theme']
}
let hasRehydrated = false;

const store = configureStore({
	reducer: persistReducer(persistConfig, rootReducer()),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false,
		}).concat(middlewares),
	devTools: process.env.NODE_ENV === 'development',
})

store.asyncReducers = {}

export const persistor = persistStore(store)

export const injectReducer = (key, reducer) => {
	if (store.asyncReducers[key]) {
		return false
	}
	store.asyncReducers[key] = reducer
	store.replaceReducer(persistReducer(persistConfig, rootReducer(store.asyncReducers)))
	persistor.persist()
	return store
}

export default store