import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    currentRouteKey: '',
    customers:[],
    customerCheckTime: null,
    customerInfoCheckTime: null,
    customerInfo:[],
}

export const commonSlice = createSlice({
	name: 'base/common',
	initialState,
	reducers: {
        setCurrentRouteKey: (state, action) => {
            state.currentRouteKey = action.payload
        },
        setCustomerCheckTime: (state, action) => {
            state.customerCheckTime = action.payload
        },
        setCustomerInfoCheckTime: (state, action) => {
            state.customerInfoCheckTime = action.payload
        },
        setCustomers: (state, action) => {
            state.customers = action.payload
        },
        setCustomerInfo: (state, action) => {
            state.customerInfo = action.payload
        }
	},
})

export const { setCurrentRouteKey,setCustomerCheckTime, setCustomerInfoCheckTime, setCustomers, setCustomerInfo } = commonSlice.actions

export default commonSlice.reducer