import { createSlice } from '@reduxjs/toolkit'

export const initialData = {
    data: {}
}

const labelColorDrawerSlice = createSlice({
    name: 'labelColorDrawer',
    initialState: {...initialData },
    reducers: {
        updateLabelColor: (state, action) => {
            state.data = { ...action.payload }
        },
        setColorLabel: (state, action) => {
            state.labelCurrent = action.payload
        }
    },
    extraReducers: {
       /* [updateDrawer.fulfilled]: (state, action) => {
            state.loading = false
        },
        [updateDrawer.pending]: (state) => {
            state.loading = true
        },*/
    }
})

export const { updateLabelColor, setColorLabel } = labelColorDrawerSlice.actions

export default labelColorDrawerSlice.reducer