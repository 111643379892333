import { createSlice } from '@reduxjs/toolkit'

export const initialData = {
    data: {},
    show:false,
    loading: false,
    list: [],
    index: []
}

const visitDrawerSlice = createSlice({
    name: 'visitDrawer',
    initialState: {...initialData },
    reducers: {
        updateDrawer: (state, action) => {
			var Products = [];

			if(action.payload.response_dict?.Content){

				let tempCooler = action.payload.response_dict?.Content?.products ? action.payload.response_dict?.Content?.products : action.payload.response_dict?.Content?.Products
				let tempData = []
				for(var key in tempCooler) {
					tempData.push(tempCooler[key])
				}
				{tempData.map((cool, index) => {
					Products.push({
						File_Inference_Output: cool.File_Inference_Output,
						File_Name: cool.File_Name,
						Image_Display_Category: cool.Image_Display_Category
					})
				})} 


			}

            state.data = { ...action.payload, Products }
        },
        closeDrawer: (state) => {
            state.show = false
        },
        openDrawer: (state) => {
            state.show = true
        },

        updateList: (state, action) => {
            let dir = [];
            action.payload.map((item) => {
                dir.push(item.id)
            })
            state.index = dir
            state.list = action.payload
        }
    },
    extraReducers: {
       /* [updateDrawer.fulfilled]: (state, action) => {
            state.loading = false
        },
        [updateDrawer.pending]: (state) => {
            state.loading = true
        },*/
    }
})

export const { updateDrawer, openDrawer, closeDrawer, updateList } = visitDrawerSlice.actions

export default visitDrawerSlice.reducer