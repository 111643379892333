import ApiService from './ApiService'
import appConfig from '../configs/app.config'
 

export async function apiSignIn (data) {
    return ApiService.fetchData({
        url: appConfig.APIURL + 'weblogin',
        method: 'post',
        headers: {'Content-Type' : 'application/json'},
        data
    })
}

export async function apiCheckAuth (data) {
    return ApiService.fetchData({
        url: appConfig.APIURL + 'webrefreshtoken',
        method: 'post',
        headers: {'Content-Type' : 'application/json'},
        data
    })
}

export async function apiSignUp (data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data
    })
}

export async function apiSignOut (data) {
	localStorage.removeItem('project_id');
	localStorage.removeItem('project_name');
    /*
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data
    })
    */
}

export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: appConfig.APIURL + 'forgetpassword',
        method: 'post',
        data
    })
}

export async function apiVerifyCodeAndChangePassword (data) {
    return ApiService.fetchData({
        url: appConfig.APIURL + 'confirmpassword',
        method: 'post',
        data
    })
}


export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data
    })
}
